<template>
  <div class="serveBindingManage">
    <ds-header title="服务绑定管理"></ds-header>
    <a-form layout="inline">
      <a-form-item label="服务名称：">
        <a-select
          :getPopupContainer="(triggerNode) => triggerNode.parentNode"
          style="width: 200px"
          placeholder="请选择"
          v-model="searchForm.serviceId"
          :filter-option="filterOption"
          show-search
        >
          <a-select-option v-for="item in serviceNameList" :key="item.id" :value="item.id">
            {{ item.serviceName }}
          </a-select-option>
        </a-select>
      </a-form-item>

      <a-form-item label="所属项目：">
<!--        <a-select-->
<!--          :getPopupContainer="(triggerNode) => triggerNode.parentNode"-->
<!--          style="width: 200px"-->
<!--          placeholder="请选择"-->
<!--          v-model="searchForm.projectId"-->
<!--        >-->
<!--          <a-select-option v-for="item in projectList" :key="item.id" :value="item.id">-->
<!--            {{ item.project_name }}-->
<!--          </a-select-option>-->
<!--        </a-select>-->
        <a-tree-select
          v-model="searchForm.projectId"
          style="width: 200px"
          :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
          placeholder="请选择所属项目"
          allow-clear
          tree-default-expand-all
        >
          <a-tree-select-node :disabled="item.disabled" :value="item.id" :title="item.project_name" v-for="item in projectList" :key="item.id">
            <a-tree-select-node :key="arr.id" :value="arr.id" :title="arr.project_name" v-for="arr in item.child">
            </a-tree-select-node>
          </a-tree-select-node>
        </a-tree-select>


      </a-form-item>

      <a-form-item label="服务状态：">
        <a-select
          :getPopupContainer="(triggerNode) => triggerNode.parentNode"
          style="width: 200px"
          placeholder="请选择"
          v-model="searchForm.state"
        >
          <a-select-option v-for="item in type" :key="item.id" :value="item.id">
            {{ item.value }}
          </a-select-option>
        </a-select>
      </a-form-item>

      <span>
        <a-button type="primary" @click="search" style="margin-top: 3px"> <a-icon type="search" />查询 </a-button>
        <a-button style="margin-left: 15px" @click="resetSearchForm">重置</a-button>
      </span>
    </a-form>
    <div style="padding-top: 15px">
      <a-button type="primary" @click="addModel()">
        <a-icon type="plus" />
        新增
      </a-button>
    </div>
    <div style="padding-top: 15px">
      <a-table
        :rowKey="(record, index) => index"
        :columns="table.columns"
        :data-source="table.dataInfo"
        :pagination="table.pagination.total ? table.pagination : false"
        @change="pageChange"
        bordered
        :scroll="{ x: 1500 }"
      >
        <span slot="oftenUseState" slot-scope="text, record" class="action">
          <a-switch :checked="record.oftenUseState === 1 ? true : false" @change="switchChange(record)" />
        </span>
        <span slot="action" slot-scope="text, record" class="action">
          <a-button class="btnClass" type="link" @click="edit(record)"> 编辑 </a-button>
        </span>
      </a-table>
    </div>

    <a-modal v-model="visibleFloor" :title="titleType" :footer="null" :maskClosable="false" :closable="false">
      <a-form-model layout="inline" ref="ruleForm" :model="params" :rules="rules">
        <a-form-model-item label="项目名称：" prop="projectId">
          <a-tree-select
            v-model="params.projectId"
            style="width: 200px"
            :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
            placeholder="请选择项目名称"
            allow-clear
            tree-default-expand-all
          >
            <a-tree-select-node :disabled="item.disabled" :value="item.id" :title="item.project_name" v-for="item in projectList" :key="item.id">
              <a-tree-select-node :key="arr.id" :value="arr.id" :title="arr.project_name" v-for="arr in item.child">
              </a-tree-select-node>
            </a-tree-select-node>
          </a-tree-select>
        </a-form-model-item>

        <a-form-model-item label="页面位置：" prop="location">
          <a-select
            show-search
            placeholder="请选择页面位置"
            option-filter-prop="children"
            style="width: 200px"
            @change="handleChangePageLocation"
            v-model="params.location"
          >
            <a-select-option v-for="item in pageLocationArray" :key="item.id">
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>

        <a-form-model-item label="模块：" style="padding-left:6%" prop="moduleId">
          <a-select
            show-search
            placeholder="请选择模块"
            option-filter-prop="children"
            style="width: 200px"
            @change="handleModuleIdChange"
            v-model="params.moduleId"
          >
            <a-select-option v-for="(item, index) in moduleData" :key="item.id">
              {{ item.moduleName }}
            </a-select-option>
          </a-select>
        </a-form-model-item>

        <a-form-model-item label="服务图标：" prop="serviceImage">
          <a-upload
            list-type="picture-card"
            class="avatar-uploader"
            :show-upload-list="false"
            :action="IMG_API + '/oss/files'"
            :before-upload="beforeUpload"
            @change="handleImgChange"
          >
          <img class="upImg" width="100px" height="100px" v-if="params.serviceImage" :src="params.serviceImage" alt="avatar" />
          <div v-else>
            <a-icon :type="loading ? 'loading' : 'plus'" />
          </div>

          </a-upload>
          <p>图片类型支持jpg/png类型，最大不能超过1M<span v-show="imageHeight" style="color: red; padding-left: 10px">{{ imageWidth }} * {{ imageHeight }}</span></p>
        </a-form-model-item>
        <a-form-model-item label="服务副标题：" :required="nowModule.moduleStyle === 2" prop="assistantTitle">
          <a-input
            placeholder="请输入副标题"
            :maxLength="20"
            v-model="params.assistantTitle"
            class="inputClass"
          />
        </a-form-model-item>

        <a-form-model-item label="服务名称：" prop="serviceId">
          <a-select
            show-search
            placeholder="请输入服务名称或服务id"
            option-filter-prop="children"
            style="width: 200px"
            v-model="params.serviceId"
          >
            <a-select-option v-for="item in serviceNameList" :key="item.id">
              {{ item.serviceNameAdd }}
            </a-select-option>
          </a-select>
        </a-form-model-item>

        <a-form-model-item label="状态：" prop="state" style="padding-left: 6%">
          <a-select
            v-model="params.state"
            class="inputClass"
            placeholder="请选择状态"
            :getPopupContainer="(triggerNode) => triggerNode.parentNode"
          >
            <a-select-option v-for="option in type" :key="option.id" :value="option.id">{{
                option.value
              }}
            </a-select-option>
          </a-select>
        </a-form-model-item>

        <a-form-model-item label="提示语：" prop="hint" style="padding-left: 5%">
          <a-input placeholder="请输入提示语" v-model="params.hint" class="inputClass" />
        </a-form-model-item>

        <a-form-model-item
          label="顺序号  ："
          v-if="this.titleType === '编辑'"
          prop="orderNumber"
          style="padding-left: 2%"
        >
          <a-input
            placeholder="1~99"
            type="number"
            max="99"
            oninput="if(value.length>2)value=value.slice(0,2)"
            v-model="params.orderNumber"
            class="inputClass"
          />
        </a-form-model-item>
      </a-form-model>
      <div style="margin-left: 70%">
        <a-button type="primary" class="footerButton" @click="submit"> 提交</a-button>
        <a-button @click="cancel">返回</a-button>
      </div>
    </a-modal>

    <ConfirmBoxModule ref="confirmBoxModule"></ConfirmBoxModule>
  </div>
</template>

<script>
import { mapState } from "vuex";
import ConfirmBoxModule from "@/components/confirmBoxModule";
import DSTable from "@/components/DSComponents/DSTable";
import { IMG_API } from "@/config";
import {
  newAddServiceBinding,
  newBindingInfoById,
  newEditServiceBinding,
  newServiceList,
  newServiceListName,
  updateOften,
  findModuleList
} from "@/api/serviceBinding";
import moment from "moment";

export default {
  name: "serveBindingManage",
  components: {
    DSTable,
    ConfirmBoxModule
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.load.loading
    }),
    ...mapState({
      projectList: (state) => {
        return state.common.projects.length && state.common.projects.map(item => {
          return {
            ...item,
            disabled: !!(item.child)
          }
        })
      }
    }),
  },
  data() {
    let checkPriority = (rule, value, callback) => {
      // 校验优先级
      if (Number(value) > 0) {
        return callback();
      }
      // 不合法
      callback(new Error("排序序号最小值为1"));
    };
    let checkAssistantTitle = (rule, value, callback) => {
      if ((value === undefined || value === '' || value === null) && this.nowModule && this.nowModule.moduleStyle === 2) {
        callback(new Error('请输入服务副标题'));
      }  else {
        callback();
      }
    }
    return {
      // 上传图片的高度
      imageHeight: 0,
      imageWidth: 0,
      // 当前选中的模块
      nowModule: '',
      // 上传图片loading
      loading: false,
      IMG_API: IMG_API,
      // 模块数据
      moduleData:[],
      // 页面位置
      pageLocationArray: [
        {
          id: 0,
          name: "首页"
        },
        {
          id: 1,
          name: "食住行"
        },
        {
          id: 2,
          name: "无忧创业"
        },
        {
          id: 3,
          name: "我的权益"
        }
      ],
      // 新增编辑弹出框
      visibleFloor: false,
      searchForm: {
        serviceId: undefined,
        projectId: undefined,
        state: undefined
      },
      editingKey: "",
      // 服务名称
      serviceNameList: [],
      titleType: "",
      id: "",
      params: {
        id: '',
        // 项目id
        projectId: undefined,
        // 项目名称
        projectName: "",
        // 服务id
        serviceId: undefined,
        // 副标题
        assistantTitle: '',
        // 页面位置
        location: '',
        // 模块
        moduleId: '',
        // 状态
        state: undefined,
        // 提示语
        hint: "",
        // 顺序号
        orderNumber: "",
        // 服务图片
        serviceImage: ""
      },

      // 状态
      type: [
        {
          id: 1,
          value: "启用"
        },
        {
          id: 2,
          value: "停用"
        },
        {
          id: 3,
          value: "暂停"
        }
      ],
      rules: {
        // 项目名称
        projectId: [{ required: true, message: "请填写项目名称", trigger: "change" }],
        // 服务名称
        serviceId: [{ required: true, message: "请选择服务", trigger: "change" }],
        // 页面位置
        location:[{required: true, message: '请选择页面位置', trigger: 'change'}],
        // 模块位置
        moduleId: [{required: true, message: '请选择模块', trigger: 'change'}],
        // 服务图片
        serviceImage: [{ required: true, message: "请上传服务图标", trigger: "blur,change" }],
        // 服务副标题
        assistantTitle:[
          { validator: checkAssistantTitle, trigger: 'blur' },
        ],
        // 状态
        state: [{ required: true, message: "请选择状态", trigger: "change" }],
        //提示语
        hint: [{ required: false, message: "已选择暂停服务，请填入提示语", trigger: "blur" }],
        // 顺序号
        orderNumber: [
          { required: true, message: "请填写顺序号", trigger: "blur" },
          { validator: checkPriority, trigger: "blur" }
        ]
      },
      table: {
        dataInfo: [],
        columns: [
          {
            title: "项目名称",
            dataIndex: "projectName",
            key: "projectName"
          },
          {
            title: "id",
            dataIndex: "id",
            key: "id"
          },
          {
            title: "服务名称",
            dataIndex: "serviceName",
            key: "serviceName"
          },
          {
            title: "页面位置",
            dataIndex: "location",
            customRender(text) {
              if (text === '0') {
                return '首页'
              } else if (text === '1') {
                return '食住行'
              }else if (text === '2') {
                return '无忧创业'
              }else if (text === '3') {
                  return '我的权益'
              }
            }
          },
          {
            title: "模块",
            dataIndex: "moduleName",
            key: "moduleName"
          },
          {
            title: "更新时间",
            dataIndex: "gmtModified",
            key: "gmtModified",
            customRender(text) {
              return text ? moment(text).format("YYYY-MM-DD HH:mm:ss") : "-";
            }
          },
          {
            title: "提示语",
            dataIndex: "hint",
            key: "hint"
          },
          {
            title: "顺序号",
            dataIndex: "orderNumber",
            key: "orderNumber"
          },
          {
            title: "使用状态",
            dataIndex: "state",
            key: "state",
            customRender(text) {
              return text == 1 ? "启用" : text == 2 ? "停用" : "暂停";
            }
          },
          {
            title: "操作",
            key: "action",
            width: "200px",
            className: "action-ql-list",
            scopedSlots: { customRender: "action" },
            fixed:'right'
          }
        ],
        //分页数据
        pagination: {
          showSizeChanger: true,
          current: 1,
          pageSize: 10,
          total: 0,
          showQuickJumper: true,
          showTotal: (total) => {
            return `共 ${total} 条`;
          }
        }
      }
    };
  },
  watch: {
    "params.state": {
      handler(val) {
        if (val === 3) {
          this.rules.hint[0].required = true;
        } else {
          this.rules.hint[0].required = false;
          this.$refs.ruleForm.clearValidate();
        }
      },
      deep: true,
      immediate: true
    }
  },
  created() {
    this.searchFormList();
    this.serviceListName();
  },
  methods: {
    // switch点击事件
    async switchChange(checked) {
      const res = await updateOften({
        id: checked.id,
        oftenUseState: checked.oftenUseState === 0 || checked.oftenUseState == null ? 1 : 0
      });
      if (res.code == 200) {
        this.$message.success("操作成功！");
        this.table.pagination.current = 1;
        this.searchFormList();
      } else {
        this.$message.warning(res.msg);
      }
    },
    // 详情
    async bindingInfoById() {
      const res = await newBindingInfoById({ id: this.id });
      if (res.code == 200) {
        this.params = {
          id: res.data.id,
          projectId: res.data.projectId,
          projectName: res.data.projectName,
          serviceId: res.data.serviceId,
          location: res.data.location !== ''? parseInt(res.data.location) : '',
          moduleId: res.data.moduleId,
          moduleName: res.data.moduleName,
          assistantTitle: res.data.assistantTitle,
          serviceImage: res.data.serviceImage,
          state: res.data.state,
          hint: res.data.hint,
          orderNumber: res.data.orderNumber
        };
        await this.getModuleList()
        await this.queryImgSize()
      }
    },
    // 编辑时查询图片的大小
    queryImgSize () {
      const module = this.moduleData.find(item => {
        return item.id === this.params.moduleId
      })
      debugger
      this.nowModule = module
      if (module && module.moduleType === 1) {
        this.imageWidth = 100
        this.imageHeight = 100
      } else if (module && module.moduleType === 2 && (module.moduleStyle === 0 || module.moduleStyle === 1 )) {
        this.imageWidth = 80
        this.imageHeight = 80
      } else if(module && module.moduleType === 2 && module.moduleStyle === 6 ) {
        this.imageWidth = 170
        this.imageHeight = 80
      } else {
        this.imageWidth = 312
        this.imageHeight = 156
      }
    },

    // 选择页面位置change时间
    handleChangePageLocation() {
      if (this.params.moduleId) {
        this.params.moduleId = ''
      }
      // 获取模块名称
      this.getModuleList()
    },
    // 获取项目名称
    handleChange(value) {
      // 清空所选的页面位置
      if (this.params.location) {
        this.params.location = ''
      }
      // 清空所选的模块
      if (this.params.moduleId) {
        this.params.moduleId = ''
      }
      // 清空上传的图片
      if (this.params.serviceImage) {
        this.params.serviceImage = ''
      }
      this.params.projectName = value;
      this.projectList.forEach((item) => {
        if (item.id === value) {
          this.params.projectName = item.project_name;
        }
      });
    },
    // 选择模块change
    handleModuleIdChange(data) {
      if (this.params.serviceImage) {
        this.params.serviceImage = ''
      }
      const module = this.moduleData.find((item) => {
        if (item.id === data) {
          return item
        }
      })
      if (module && module.moduleType === 1) {
        this.imageWidth = 100
        this.imageHeight = 100
      } else if (module && module.moduleType === 2 && (module.moduleStyle === 0 || module.moduleStyle === 1 )) {
        this.imageWidth = 80
        this.imageHeight = 80
      } else if (module && module.moduleType === 2 && module.moduleStyle === 6) {
        this.imageWidth = 170
        this.imageHeight = 80
      } else {
        this.imageWidth = 312
        this.imageHeight = 156
      }
      this.nowModule = module
    },
    // 服务名称选择框
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    },

    // 处理传参数据
    searchFormList() {
      this.serviceList(this.table.pagination.pageSize, this.table.pagination.current, this.searchForm);
    },
    // 图片上传
    handleImgChange(info) {
      if (info.file.status === "uploading") {
        this.loading = true;
        return;
      }
      if (info.file.status === "done") {
        this.loading = false;
        // this.params.serviceImage = info.file.response.redirect_uri;
        this.$set(this.params, 'serviceImage', info.file.response.redirect_uri)
      }
    },

    // 图片限制
    beforeUpload(file) {
      if (this.nowModule === '') {
        this.$message.error("请先选择模块");
        return Promise.reject();
      }
      // 校验格式
      const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png" || file.type === "image/jpg";
      if (!isJpgOrPng) {
        this.$message.error("请上传jpg、png格式");
        return Promise.reject();
      }
      // 校验大小
      const isLt2M = file.size / 1024 / 1024 <= 1;
      if (!isLt2M) {
        this.$message.error("图片最大1M");
        return Promise.reject();
      }
      let that = this
      // 校验比例
      const isSize = new Promise(function (resolve, reject) {
        const URL = window.URL || window.webkitURL;
        const img = new Image();
        img.onload = function () {
          const valid = (img.width === that.imageWidth && img.height === that.imageHeight)
          valid ? resolve() : reject();
        };
        img.src = URL.createObjectURL(file);
      }).then(
        () => {
          return file;
        },
        () => {
          this.$message.error("图片不规范，请按要求上传");
          return Promise.reject();
        }
      );
      return isJpgOrPng && isLt2M && isSize;
    },

    // 获取列表
    async serviceList(size, page, data) {
      const res = await newServiceList(size, page, data);
      if (res.code == 200) {
        this.table.dataInfo = res.data.items;
        this.table.pagination.total = res.data.total;
      }
    },

    // 模块list
    async getModuleList () {
      if (this.params.projectId === '' || this.params.projectId === undefined) {
        this.$message.warning("请先选择项目名称")
        this.params.location = ''
        return;
      }
      if (this.params.location === '') {
        this.$message.warning("请先选择页面位置")
        return;
      }
      await findModuleList(this.params.location, this.params.projectId).then(res => {
        if (res.code === '200') {
          this.moduleData = res.data
        }
      })
    },
    // 服务名称
    async serviceListName() {
      const res = await newServiceListName();
      if (res.code === '200') {
        res.data.forEach((item) => {
          item.serviceNameAdd = item.serviceName + " " + item.id;
        });
        this.serviceNameList = res.data;
      }
    },

    // 新增、编辑提交
    submit() {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          if (this.titleType == "新增") {
            const res = await newAddServiceBinding(this.params);
            if (res.code == 200) {
              this.$message.success("新增成功");
              this.visibleFloor = false;
              this.searchFormList();
            } else {
              this.$message.error(res.msg);
            }
          } else if (this.titleType == "编辑") {
            const res = await newEditServiceBinding(this.params);
            if (res.code == 200) {
              this.$message.success("编辑成功");
              this.table.pagination.current = 1;
              this.visibleFloor = false;
              this.searchFormList();
            } else {
              this.$message.error(res.msg);
            }
          }
        } else {
          return false;
        }
      });
    },

    // 关闭蒙层的操作(取消校验)
    async cancel() {
      this.params.hint = "";
      this.visibleFloor = false;
      this.$refs.ruleForm.resetFields();
    },

    // 新建模块
    addModel() {
      this.titleType = "新增";
      this.visibleFloor = true;
      this.params = {
        // 项目id
        projectId: undefined,
        // 项目名称
        projectName: "",
        // 服务id
        serviceId: undefined,
        // 状态
        state: undefined,
        // 提示语
        hint: ""
      };
    },

    //查询
    search() {
      this.table.pagination.current = 1;
      this.searchFormList();
    },

    //重置
    resetSearchForm() {
      this.table.pagination.current = 1;
      this.table.pagination.pageSize = 10;
      this.searchForm = this.$options.data.call(this).searchForm;
      this.searchFormList();
    },

    //编辑
    edit(record) {
      this.id = record.id;
      this.titleType = "编辑";
      this.bindingInfoById();
      this.visibleFloor = true;
    },

    //分页操作
    pageChange(option) {
      this.table.pagination.current = option.current;
      this.table.pagination.pageSize = option.pageSize;
      this.searchFormList();
    }
  },
  mounted() {
    let handleResize = ()=> {
      let width = document.getElementsByClassName('serveBindingManage')[0].clientWidth;
      console.log(width,'=====');
      if(width>1600){
        this.table.columns[this.table.columns.length-1].fixed = '';
      }else{
        this.table.columns[this.table.columns.length-1].fixed = 'right';
      }
    }
    window.addEventListener('resize', handleResize);
    handleResize()
  },
};
</script>

<style lang="scss" scoped>
.btnClass {
  padding: 0px;
  padding-right: 5px;
}

.footerButton {
  margin-top: 10px;
  margin-right: 10px;
}

.action a {
  color: #1890ff;
  margin-right: 15px;
}

.action a:hover {
  color: #40a9ff;
}

.field {
  font-size: 12px;
  color: #333;
  display: inline-block;
  height: auto;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 80px;
}

.inputClass {
  width: 200px;
}

.inputColor {
  color: #bdbdbd !important;
}
::v-deep .avatar-uploader > .ant-upload {
  margin-top: 14px;
  margin-left: 8px;
  width: 128px;
  height: 128px;
}
.upImg {
  width: 109px;
  height: 109px;
}
</style>
